import React from "react"
import { css } from "@emotion/react"

const Guarantees = ({h2,h5,WithSlide}) => {
  return (
    <div css={GuaranteesSectionStyles}
    >
            <div id="guarantees-content-ctn" >

          {h5 !== undefined &&
          <h2 dangerouslySetInnerHTML={{ __html: h2 }} />
          }

          {h5 !== undefined &&
          <h5 dangerouslySetInnerHTML={{ __html: h5 }} />
          }


          <div id="guarantees-list-ctn">
            <div className= {WithSlide ? "guarantee-ctn slide " : "guarantee-ctn" }>
              <svg
                id="note-board-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 376 456"
              >
                <use href="/icons-sprite-map.svg#note-board-icon" />
              </svg>
              <div>
                <h3>You won't need to do it twice</h3>
                <p>
                  Health is demanding. To avoid health’s authorithies rejection,
                  your study needs to be up to date with all the good practices.
                </p>
                <h4>our norms certifications:</h4>
                <ul>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>ICH - GxP</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>FDA (Title 21 CFR Part 11)</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>Data Protection (GDPR)</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>HIPAA</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>Internal SOPs</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>EMA</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={WithSlide ? "guarantee-ctn slide " : "guarantee-ctn" }>
              <svg
                id="star-big-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 465 449"
              >
                <use href="/icons-sprite-map.svg#star-big-icon" />
              </svg>
              <div>
                <h3>No bad surprise on the road</h3>
                <p>
                  You won’t have to endure a sleepless night because of a poor
                  study’s design.
                </p>
                <h4>our norms certifications:</h4>
                <ul>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>Tailored-Made Solutions or EDC systems</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>
                      Ressources optimization (with additionnal solution: Python
                      & R)
                    </span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>Double Data Quality Check (with Python & R)</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>User Friendly & UX optimized</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>
                      Multi-lingual & Fully Responsive (Phone, Tablet,...)
                    </span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>Flexible and able to Manage The Unexpected</span>
                  </li>
                  <li>
                    <svg
                      className="checkmark-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                    >
                      <use href="/icons-sprite-map.svg#checkmark-icon" />
                    </svg>
                    <span>Always on time</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Guarantees

const GuaranteesSectionStyles = css`

  h2 {
    color: #080829;
    margin-top: 7rem;
  }

 h5 {
    color: #080829;
    margin-bottom: 3rem;
  }

  #guarantees-list-ctn {
    max-width: 59.53rem;
    margin: auto;
  }

  #note-board-icon {
    width: 18.05rem;
    max-width: 100%;
    position: relative;
    top: 0.4rem;
  }

  #star-big-icon {
    width: 22.32rem;
    max-width: 100%;
    position: relative;
    top: 0.4rem;
  }

  #people-big-icon {
    width: 17.9548rem;
    max-width: 100%;
    position: relative;
    top: 0.4rem;
  }

  .checkmark-icon {
    min-width: 0.96rem;
    max-height: 0.816rem;
    padding-right: 0.6rem;
  }

  .guarantee-ctn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 8rem;
  }

  .guarantee-ctn > div {
    max-width: 32rem;
    margin-left: 5rem;
  }

  .guarantee-ctn h3 {
    color: #1d2554;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .guarantee-ctn h4 {
    color: #1d2554;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0.9rem;
  }

  .guarantee-ctn h4:last-of-type {
    margin-top: 0;
  }

  .guarantee-ctn p {
    font-weight: 300;
    color: #1d2554;
  }

  .guarantee-ctn ul {
    list-style-type: none;
    padding: 0;
    font-weight: 300;
    color: #1d2554;
  }

  .guarantee-ctn ul li {
    padding: 0.5rem;
    border-bottom: 0.144rem solid #c6c8d4;
    display: flex;
  }

  @media (max-width: 1023px) {
    #guarantees-content-ctn {
      padding-bottom: 2rem;
    }

    #guarantees-content-ctn h5 {
      margin-bottom: 4rem;
    }

    .guarantee-ctn {
      flex-direction: column;
      margin-bottom: 4rem;
    }

    .guarantee-ctn > div {
      margin: 0;
    }

    .guarantee-ctn h3 {
      text-align: center;
    }

    .guarantee-ctn p {
      text-align: left;
      margin-bottom: 2.5rem;
    }

    .guarantee-ctn > svg {
      margin-bottom: 1rem;
    }

    .guarantee-ctn:last-of-type h4 {
      text-align: center;
    }

    .guarantee-ctn:last-of-type p {
      margin-bottom: 1rem;
    }

    #note-board-icon {
      width: 14.445rem;
    }

    #star-big-icon {
      width: 17.856rem;
    }

    #people-big-icon {
      width: 14.36rem;
    }
  }

  @media (max-width: 479px) {
    #note-board-icon {
      width: 13rem;
    }

    #star-big-icon {
      width: 16rem;
    }

    #people-big-icon {
      width: 12.92rem;
    }
  }
`
