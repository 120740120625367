import React from "react"
import "../assets/styles/global.css"

import { graphql, useStaticQuery } from "gatsby"
import HeaderServices from "../components/Header/HeaderServices/HeaderServices"
import FullPageLayout from "../layouts/FullPageLayout"
import Section from "../components/Section/Section"
import DelimiterSVG, { SVGDelimiter } from "../components/Section/SVGDelimiter"
import EbookComponent from "../components/Content/Ebooks/EbookComponent"
import StudySteps from "../components/Content/StudySteps/StudySteps"
import Guarantees from "../components/Content/Guarantees"
import HealthStudy from "../components/Content/HealthStudy"
import Testimonials from "../components/Content/Testimonials/Testimonials"
import ContactComponent from "../components/Content/Contact/ContactComponent"
import Footer from "../components/Bar/footer"
import { DATA_MANAGEMENT_TOOLTIPS } from "../const/MenuTooltips"
import Carroussel from "../components/Carroussel/Carroussel"
import { SEO_OPTIONS_DM } from "../const/SEOOptions"

const DataManagementPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    {  
      headerImage: file(relativePath: { eq: "data-management.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }  
    } 
  `)

  const fullpageOptions = {
    navigationTooltips: DATA_MANAGEMENT_TOOLTIPS,
    slidesNavigation: true
  }


  return (
    <FullPageLayout
      options={fullpageOptions}
      SEOOptions={SEO_OPTIONS_DM}
      background={"white"}
      location={location}
    >
      <Section isFirstSection>
        <HeaderServices
          h1={`<span>data </span> <br /><span>management</span>`}
          p={`We collect and process data to guide
            decision makers through health progress.`}
          img={data.headerImage.childImageSharp.fluid}
          alt={`Data management`}
        />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Line}
        fillColor={"#fff"}
        Background={`#010921`}
      />


      <Section
        Background={`#010921`}
      >
        <EbookComponent isOnEbookPage />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Rounded}
        fillColor={"#010921"}
        Background={`#141B48`}
      />

      <Section
        GradientBackground={`linear-gradient(180deg,rgba(20, 27, 72, 1) 0%,rgba(8, 8, 41, 1) 100%)`}
      >
        <StudySteps
          h2={`your study within 4 steps`}
          h5={`Adaptive methods, Precise Execution`}
          CTA={`Ask A Free Tailored Study Blueprint`}
        />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Rounded}
        fillColor={"#080829"}
        Background={`#fff`}
      />

      <Section>
        <Guarantees
          h2={`your study, without anxiety`}
          h5={`Our guarantees for a successful study`}

        />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Line}
        fillColor={"#fff"}
        Background={`#010921`}
      />
      <Section
        Background={`#010921`}
      >
        <HealthStudy
          h2={`YOUR HEALTH STUDY, EASED`}
          h5={`No more waste of energy, time nor fund`}
          ImgStroke={true}
          Color={`#fff`}
        />
      </Section>

      <Section
        Background={`#010921`}
      >
        <Carroussel
          h2={'Download Our Free Guides'}
          h5={'Complex processes, simplified'}
          CTA={'ALL FREE GUIDES'}
        />
      </Section>

      <Section
        Background={`#010921`}
      >
        <Testimonials />
      </Section>


      <DelimiterSVG
        shape={SVGDelimiter.Shape.Line}
        fillColor={`#010921`}
        Background={`transparent`}
      />

      <ContactComponent
        DisplayContactForm={true}
      />


      <Footer location={location} />
    </FullPageLayout>
  )
}

export default DataManagementPage


