import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { css } from "@emotion/react"

const HealthStudy = ({ h2, h5, ImgStroke, ImgFill, Color }) => {
  const data = useStaticQuery(graphql`
    {
      healthStudyDevicesStroke: file(
        relativePath: { eq: "health-study-devices-stroke.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
        healthStudyDevicesFill: file(
        relativePath: { eq: "health-study-devices-fill.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <div css={HealthStudySectionStyles}
         style={{
           width:"100%"
         }}
    >
      <div className={"health-study--ctn__wrapper"}>
        <div className={"health-study--text-ctn__wrapper"}>

          {h2 !== undefined &&
          <h2 dangerouslySetInnerHTML={{ __html: h2 }} style={{
            color: Color
          }} />          }

          {h5 !== undefined &&
          <h5 dangerouslySetInnerHTML={{ __html: h5 }} style={{
            color: Color
          }} />
          }

          <p style={{
            color: Color
          }}>
            Get safer answers thanks to flexible and highly accurate tools and services.
          </p>
          <p style={{
            color: Color
          }}>
            Each step is designed to alleviate the life of our Sponsors, their Team and Patients.
          </p>
        </div>

        <div className="health-study-gatsby-image">

          {ImgStroke !== undefined &&
          <Img
            style={{ width: "100%" }}
            fluid={data.healthStudyDevicesStroke.childImageSharp.fluid}
            alt="Health study devices"
          />}

          {ImgFill !== undefined &&
          <Img
            style={{ width: "100%" }}
            fluid={data.healthStudyDevicesFill.childImageSharp.fluid}
            alt="Health study devices"
          />}
        </div>
      </div>
    </div>
  )
}

export default HealthStudy

const HealthStudySectionStyles = css`

  h2,
  h5 {
    text-align: left;
  }

  p {
    color: #efeff1;
    font-weight: 300;
    max-width: 21rem;
  }

  #health-study-img-ctn {
    max-width: 38.07rem;
  }

  .health-study--text-ctn__wrapper {
    margin: auto;
  }

  .health-study-gatsby-image {
    max-width: 32.07em;
    width: 100%;
    display: block;
  }

  .gatsby-image-wrapper {
    margin-left: auto;
    width: 90% !important;
  }

  .health-study--ctn__wrapper {
    display: flex;
    margin: auto 15%;
  }


  @media (max-width: 1023px) {
    .health-study-gatsby-image {
      max-width: 30rem;
      position: absolute;
      top: -8rem;
      left: 31rem;
      width: 100%;
      display: block;
      margin: auto;
    }

    .gatsby-image-wrapper {
      width: 80% !important;
      margin: 2em auto !important;
    }

    .health-study--ctn__wrapper {
      display: flex;
      margin: auto 15%;
      flex-direction: column-reverse;
    }

    #section-health-study {
      padding-top: 7rem;
      padding-bottom: 6rem;
    }

    #health-study-content-ctn h2,
    #health-study-content-ctn h5,
    #section-health-study p {
      text-align: center;
    }

    #section-health-study p {
      margin-left: auto;
      margin-right: auto;
    }

    #section-health-study p:last-of-type {
      margin-bottom: 4rem;
    }

    #health-study-img-ctn {
      position: static;
      margin: auto;
    }

    .health-study-gatsby-image {
      position: static;
      margin: auto;
    }
  }

  @media (max-width: 479px) {
    #section-health-study {
      padding-bottom: 3rem;
    }

    .health-study-gatsby-image {
      width: 100%;
      margin: 0;
    }
  }
`
